import PropTypes from 'prop-types';
import React from 'react';
import '../../static/fonts.scss';
import '../assets/stylesheets/pages/index.scss';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/SEO/SEO';

import TeaserCard from '../components/TeaserCard/TeaserCard';

export default function HomePage({ data, pageContext }) {
  const { edges } = data.allMarkdownRemark;
  return (
    <>
      <SEO />
      <section className="card-container">
        {edges.map((result, index) => {
          const {
            slug,
            author,
            publishDate,
            headline,
            subhead,
            image,
          } = result.node.frontmatter;
          const cardContent = {
            slug,
            author,
            publishDate,
            headline,
            subhead,
            image,
          };
          return <TeaserCard key={index} content={cardContent} />;
        })}
      </section>
    </>
  );
}

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.any,
};

export const query = graphql`
  query($skip: Int = 0, $pageSize: Int = 2) {
    allMarkdownRemark(
      filter: { frontmatter: { author: { glob: "*" } } }
      sort: { order: DESC, fields: [frontmatter___publishDate] }
      limit: $pageSize
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            slug
            headline
            subhead
            author
            publishDate
            updateDate
            image {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
