import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import './TeaserCard.scss';
import Img from 'gatsby-image';
import ByLine from '../ByLine/ByLine';

export default function TeaserCard({
  content: { slug, author, publishDate, headline, subhead, image },
}) {
  let imageElement = '';
  if (image) {
    imageElement = <Img fluid={image.childImageSharp.fluid} />;
  }
  return (
    <article className="teaser-card">
      <ByLine author={author} publishDate={publishDate} />
      <Link to={slug} className="teaser-card__link">
        <h1 className="teaser-card__heading">{headline}</h1>
        {imageElement}
        <p className="teaser-card__subhead">{subhead}</p>
      </Link>
    </article>
  );
}

TeaserCard.propTypes = {
  content: PropTypes.shape({
    slug: PropTypes.any,
    author: PropTypes.any,
    publishDate: PropTypes.any,
    headline: PropTypes.any,
    subhead: PropTypes.any,
    image: PropTypes.any,
  }),
};
